import React from "react";

const Item = ({ item, optionClass, styleClass }) => {
  return (
    <div className={`column ${optionClass}`}>
      <div className={`box borde-puntos is-shadowless ${styleClass}`}>
        <h1 className={`title is-4 ${styleClass}`}>{item.nombre}</h1>
        <div className="content">
          {item.texto.map((tex, index) => (
            <p key={index} className="is-size-6 has-text-left">
              {tex}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Item;
