const tipos = [
  {
    key: 0,
    texto: "Actividades Deportivas",
    cssClass: "color-azul",
    tipo: "act00",
    info: "Todas las actividades: 1 hora al día (2 días a la semana)"
  },
  {
    key: 1,
    texto: "Actividades de Baile",
    cssClass: "color-naranja",
    tipo: "act01",
    info: "Todas las actividades: 1 hora al día (2 días a la semana)"
  },
  {
    key: 2,
    texto: "Actividades Creativas",
    cssClass: "color-verde",
    tipo: "act02",
    info: "Todas las actividades: 1 hora al día (2 días a la semana)"
  },
  {
    key: 3,
    texto: "Talleres de Aprendizaje, idiomas y apoyo",
    cssClass: "color-morado",
    tipo: "act03",
    info: "Todas las actividades: 1 hora al día (2 días a la semana)"
  },
  {
    key: 4,
    texto: "Servicios de Conciliación",
    cssClass: "color-rosa",
    tipo: "act04",
    info: ""
  },
  {
    key: 5,
    texto: "Para todos los padres",
    cssClass: "color-verde-oliva",
    tipo: "act05",
    info: ""
  }
];

export default tipos;
