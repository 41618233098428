import React, { useState } from "react";

import Hero from "../../common/hero";
import TiposActividades from "./layout/tiposActividades";
import ListaItems from "./layout/tiposActividades/listaItems";

//Importación de datos
import datosTiposActividades from "./datos/datosTiposActividades";
import datosExtraescolares from "./datos/datosExtraescolares";

const Extraescolares = () => {
  const [data, setData] = useState({ dato: [] });

  const mostrarContenido = valor =>
    setData({
      dato: datosExtraescolares.filter(item => item.tipo === valor.tipo),
      ...valor
    });

  return (
    <>
      <Hero texto="Actividades Extraescolares" />
      <div className="container">
        <TiposActividades
          data={datosTiposActividades}
          onOptionClick={mostrarContenido}
        />
      </div>

      {data !== {} && (
        <div className="container">
          <Hero
            texto={data.texto}
            subtexto={data.info}
            styleClass={data.cssClass}
          />
          <ListaItems data={data.dato} styleClass={data.cssClass} />
        </div>
      )}
    </>
  );
};

export default Extraescolares;
