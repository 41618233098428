import React from "react";

import "./tipoActividad.scss";

const TipoActividad = ({ data, onClick }) => {
  const optionClick = event => {
    event.preventDefault();
    onClick(data);
  };

  const { cssClass, texto } = data;

  return (
    <div className="column is-one-third has-text-centered">
      <div className={`box is-shadowless borde-puntos ${cssClass}`}>
        <a className={`subtitle ${cssClass}`} onClick={optionClick} href="/#">
          {texto}
        </a>
      </div>
    </div>
  );
};

export default TipoActividad;
