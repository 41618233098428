// Actividades Deportivas
const act00 = [
  {
    nombre: "Fútbol",
    texto: [
      "Se trata de una actividad sana y divertida, donde el alumno desarrolla un comportamiento cooperativo, tanto dentro como fuera del terreno del juego, fomentando el respeto por los demás, además de adquirir un mayor dominio técnico y táctico del juego.",
      "Recomendado para:",
      "- Educación Primaria (prebenjamines, benjamines, alevines)"
    ]
  },
  {
    nombre: "Crossfit Kids",
    texto: [
      "Desde bien pequeños los niños vivirán una experiencia con el deporte que le harña desarrollar sus habilidades físicas a través de juegos y entrenamientos adaptados a su edad.",
      "Los niños mejorarán el equilibrio, la resistencia muscular, la fuerza, flexibilidad, entre otras, y todo ¡lo hacemos jugando!",
      "Recomendado para:",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Baloncesto",
    texto: [
      "Los participantes adquieren el valor del trabajo en equipo, además de aprender diferentes técnicas propias de este deporte, a través de juegos y pequeñas liguillas. Sin lugar a dudas, es una actividad para encestar canasta al compañerismo.",
      "Recomendado para:",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Voley",
    texto: [
      "Una actividad divertida, en la que se trabaja la rapidez, el control físico y mental, de forma inconsciente y cooperativa. Es una excelente extraescolar para que los niños participen y se integren.",
      "Recomendado para:",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Balonmano",
    texto: [
      "Se trata de un deporte colectivo, donde los niños trabajarán valores tan importantes como el compañerismo, la superación, las normas y valores por el deporte. ¡Vive la experiencia con nuestros monitores!",
      "Recomendado para:",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Peque-deporte",
    texto: [
      "Los alumnos solicitan actividades divertidas, variadas y muy dinámicas. En esta extraescolar los más chiquitines podrán iniciarse a la actividad deportiva, mediante juegos sencillos, adquirirán, de forma paulatina, habilidades motrices y conocerán diferentes herramientas para el juego cooperativo.",
      "Recomendado para:",
      "- Educación Infantil"
    ]
  },
  {
    nombre: "Multideporte",
    texto: [
      "¿Deseas una actividad donde los niños conozcan distintos deportes? iEsta es la que andas buscando! Se trata de la extraescolar perfecta para que todos puedan desarrollar una actividad deportiva general y muy lúdica. Mediante actividades motrices, y juegos en equipo conocerán diferentes deportes.",
      "Recomendado para:",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Gimnasia rítmica",
    texto: [
      "¡Iníciate a esta disciplina deportiva! La elegancia, la flexibilidad, el sentido del ritmo y la musicalidad se trabajarán mediante actividades, divertidas y creativas.",
      "Recomendado para:",
      "- Educación Infantil",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Yoga kids",
    texto: [
      "El Yoga se trata de una actividad idónea para que los más pequeños aprendan a escuchar su cuerpo, su mente y desarrollen su capacidad física. Y, ¿sabes qué? ¡Lo hacemos a través del juego!",
      "Recomendado para:",
      "- Educación Infantil",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Peque-patines",
    texto: [
      "Cada vez es más común ver a los niños caminando y circulando sobre ruedas por las aceras, parques, jardines y pistas. Sin duda, esta actividad pretende iniciar a los niños en el patinaje, a través de juegos, giros, etc.",
      "Recomendado para:",
      "- Educación Infantil"
    ]
  },
  {
    nombre: "Patinaje",
    texto: [
      "Para aquellos alumnos que ya controlan el equilibrio sobre ruedas, Aristas ofrece esta actividad, donde los niños mejoran la técnica de frenadas, avances, giros, y... ¡montan coreografías en equipo! ¡Diviértete sobre ruedas!",
      "Recomendado para:",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Gimnasia estética",
    texto: [
      "Mezclando el baile y la gimnasia rítmica, los participantes desarrollan técnicas y habilidades motrices, donde la coordinación, el equilibrio, la elegancia, estilo y la flexibilidad son sus señas de identidad.",
      "Recomendado para:",
      "- Educación Infantil",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Artes marciales",
    texto: [
      "Karate, Judo y Taekwondo",
      "Sin lugar a duda estas disciplinas nos ayudan a mejorar la concentración, la autoestima, y sobre todo les brinda a Los niños la oportunidad de desarrollar su autocontrol y su capacidad física.",
      "Recomendado para:",
      "- Educación Infantil",
      "- Educación Primaria"
    ]
  }
];
// Actividades de Baile
const act01 = [
  {
    nombre: "Chiqui Baile",
    texto: [
      "Mediante ejercicios musicales, los niños trabajarán el sentido del ritmo y a coordinación. Se trata de una actividad lúdica y muy divertida, donde despertaremos en los más pequeños el gusto por diferentes estilos musicales a través de coreografías adaptadas, siempre, a la edad de los participantes.",
      "Recomendado para:",
      "- Educación Infantil"
    ]
  },
  {
    nombre: "1, 2, 3, ¡A bailar!",
    texto: [
      "Esta actividad extraescolar acoge diferentes estilos musicales, lo que permite a los niños conocer distintos estilos, según el trimestre en el que se encuentren. Partiremos de la danza clásica, pasando por la danza española, baile moderno, los bailes latinos, y las danzas urbanas. Partiendo de la actividad dinámica, trabajaremos la coordinación, el sentido del ritmo y la interpretación musical de diferentes estilos.",
      "Recomendado para:",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Bailes Urbanos",
    texto: [
      "En esta extraescolar trabajaremos el Hip-Hop, Street Dance y el Funky, iniciándose así los alumnos en el baile urbano y conociendo diferentes técnicas y pasos de esta modalidad. Además se trabajará la interpretación facial, tan importante en este tipo de baile. ¡Te sorprenderás al verlos bailar!",
      "Recomendado para:",
      "- Educación Infantil",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Danza española",
    texto: [
      "Se trata de una actividad extraescolar donde los niños conocerán el flamenco y las sevillanas. Nuestros monitores cualificados montaráan coreografías con los pasos más característicos de esta disciplina, adquiriendo así la coordinación, el sentido del ritmo, el control postural, entre otros aspectos básicos. ¡Qué arte tiene esta extraescolar!",
      "Recomendado para:",
      "- Educación Infantil",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Danza clásica",
    texto: [
      "¿Sabías que la danza clásica es la base de muchos tipos de baile? En esta extraescolar los participantes desarrollarán los movimientos y técnicas de giro, el control postural, la flexibilidad, así como el sentido del ritmo a través de diferentes piezas musicales. Los niños crearán coreografías clásicas, usando como método de aprendizaje el juego y diferentes actividades dinámicas.",
      "Recomendado para:",
      "- Educación Infantil",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Zumba kids",
    texto: [
      "La zumba está de moda y es una de las actividades más practicadas últimamente para mover el esqueleto. Los participantes podrán escuchar y bailar, mediante coreografías sencillas, las canciones de ritmos latinos de actualidad, trabajando la coordinación, el equilibrio y el sentido del ritmo.",
      "Recomendado para:",
      "- Educación Infantil",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Baile moderno",
    texto: [
      "El pop más actual ganará prestigio en esta actividad extraescolar. Esta actividad permite a los niños aprender coreografías con las canciones de moda, aquellas que suenan en la radio y que todos identificamos. Se trata de una actividad donde el trabajo en equipo es muy importante, no sólo trabajaremos la coordinación individual, sino que el grupo será muy importante. Con juegos y herramientas para perder la vergüenza, ¡montaremos unas coreografías sorprendentes!",
      "Recomendado para:",
      "- Educación Infantil",
      "- Educación Primaria"
    ]
  }
];

// Actividades Creativas
const act02 = [
  {
    nombre: "Cocina creativa",
    texto: [
      "2 horas al día (1 día a la semana)",
      "Se trata de una de nuestras actividades estrella, ¿sabe por qué? Los niños cocinan, experimentan, tocan y observan cómo se comportan los alimentos. Eligiendo esta opción los alumnos conocerán diferentes tipos de recetas, cocinarán con instrumentos de cocina profesionales y le darán rienda suelta a la imaginación.",
      "Recomendado para:",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Maestros de la moda",
    texto: [
      "2 horas al día (1 día a la semana)",
      "Este taller se convertirá en un espacio creativo, donde trabajaremos con técnicas de estampación, aprenderemos a cose botones, y crearemos nuestras propias colecciones de abalorios y bisutería. ¡Todo un desafío para las mentes creativas!",
      "Recomendado para:",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Música y movimiento",
    texto: [
      "Esta extraescolar aúna música, instrumentos, creatividad y, sobre todo... ¡diversión! Trabajaremos el sentido del ritmo, la expresión corporal, las artes escénicas y circenses. En definitiva, esta extraescolar pretende dotar a los niños de muchísima diversión y ganas de aprender mediante el juego.",
      "Recomendado para:",
      "- Educación Infantil",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Guitarra",
    texto: [
      "Son diferentes estilos musicales los que tienen en común este instrumento y se trata de una actividad extraescolar donde ayudaremos a los niños a que descubran qué posibilidades tiene el rasgado de las cuerdas, invitándolos a aprender la técnica de una forma muy motivadora.",
      "Recomendado para:",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Escuela de rock",
    texto: [
      "En esta actividad extraescolar descubriremos cómo ha evolucionado este estilo musical con el paso del tiempo, interpretaremos diferentes canciones de los artistas más importantes, y... ¡crearemos nuestra propia banda de rock! Para ello será necesario que los alumnos acudan con la guitarra y, de forma paulatina, iremos aprendiendo acordes, técnicas y canciones. Are you ready?",
      "Recomendado para:",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Artes circenses",
    texto: [
      '"Afortunados aquellos que aprendieron a reirse de si mismos, porque así no les faltará motivo por el que sonreir". En esta actividad extraescolar, sin lugar a dudas, los niños mejoran su autoestima, se desinhiben, trabajan en equipo y ¡conocen diferentes estrategias para hablar en público! Además, los niños trabajarán el equilibrio, el sentido del ritmo. trucos de magia, la expresión corporal, con malabares, acrobacias en pareja, etc. ¡Los monitores más divertidos estarán al cargo de esta actividad!',
      "Recomendado para:",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Artes escénicas",
    texto: [
      "El aula se convertirá en un taller de aprendizaje personal, donde la improvisación, el trabajo en equipo, la expresión de las emociones serán el eslabón ineludible de esta extraescolar. El arte dramático es una actividad que nos enriquece como personas, ya que conocemos nuestras posibilidades de escucha, análisis y desarrollamos la capacidad de empatía con otras personas.",
      "Recomendado para:",
      "- Educación Infantil",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Taller de manualidades",
    texto: [
      "Los alumnos que formen parte de este club de manualidades, desarrollarán sus apacidades creativas, fomentando el uso de la imaginación y las diferentes técnicas de expresión plástica. ¡Crearemos pequeños artistas!",
      "Recomendado para:",
      "- Educación Infantil",
      "- Educación Primaria"
    ]
  }
];
// Talleres
const act03 = [
  {
    nombre: "Pásalo ciencia",
    texto: [
      "2 horas al día (1 día a la semana)",
      "En esta actividad extraescolar el alumnado aprenderá y disfrutará jugando con la ciencia, trabajando diversas disciplinas, tales como la mecánica, quimica, física, informática, electrónica, arquitectura, neumática, y... ¡descubriremos que la ciencia nos rodea!",
      "Recomendado para:",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Ajedrez",
    texto: [
      "Los juegos de estrategias son fundamentales para que los niños desarrollen el pensamiento lógico-matemático, y la capacidad para resolver cualquier tipo de problema. Por eso nosotros contamos con ¡el kamikaze del tablero! Los niños aprenden las técnicas fundamentales y mediante liguillas, juegos y desafíos, los niños se convierten en ¡los reyes del tablero!",
      "Recomendado para:",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Logopedia",
    texto: [
      "El departamento pedagógico y de logopedia de Aristas pone a disposición del centro los especialistas oportunos para aquellos alumnos que presentan dificultades específicas en el habla para ayudarles a superarlas a través de la intervención más adecuada en cada caso.",
      "Recomendado para:",
      "- Educación Infantil",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Pequeños superhéroes",
    texto: [
      "Educación emocional",
      "En este taller trabajaremos los impulsos, nuestras emociones, y la capacidad para reconocerlas y aceptarlas. Se trata de una extraescolar terapéutica, donde a través del juego, los niños mejorarán su confianza, trabajarán la empatía y se desarrollarán como héroes responsables y felices.",
      "Recomendado para:",
      "- Educación Infantil",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Ludoenglish",
    texto: [
      "En Aristas contamos con un equipo altamente cualificado, y hemos preparado estos últimos años unas programaciones innovadoras, contando con nuevas metodologías de trabajo, centrándonos en los proyectos y centros de interés de los más pequeños. ¡Aprenderás inglés jugando, cooperando y sonriendo! A través de cuentos, canciones, talleres y dinámicas los pequeños adquirirán conocimientos de una forma muy lúdica.",
      "Recomendado para:",
      "- Educación Infantil"
    ]
  },
  {
    nombre: "English club",
    texto: [
      "El inglés es una de las lenguas más habladas en el mundo, y no porque sea un modismo, sino porque se ha convertido en un idioma universal en los continentes. Así pues, a través de metodologias innovadoras, Los integrantes de este club, aprenderán este idioma y afianzarán contenidos de una forma muy lúdica.",
      "Recomendado para:",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Petit Français",
    texto: [
      "En Aristas nos preocupamos por los idiomas, y hemos diseñado una metodología innovadora para que los más pequeños conozcan la cultura, costumbres, música... buscaremos diferentes centros de interés, para que Los niños aprendan este idioma. ¡Aprende jugando!",
      "Recomendado para:",
      "- Educación Infantil"
    ]
  },
  {
    nombre: "Le club des enfants",
    texto: [
      "En nuestro club de idiomas, tenemos un objetivo claro: enseñar hablando. Desde bien pequeños, los niños conocerán canciones, cultura. monumentos, ciudades, animales... ¡y aprenderán jugando! Trabajaremos la comunicación oral, La expresión, el oído y, perderemos el miedo a hablar un nuevo idioma.",
      "Recomendado para:",
      "- Educación Primaria"
    ]
  },
  {
    nombre: "Iniciación idiomas",
    texto: ["- Iniciación Alemán", "- Iniciación Chino", "- Iniciación Árabe"]
  },
  {
    nombre: "Refuerzo escolar",
    texto: [
      "Nuestros hijos necesitan un refuerzo y apoyo individualizado en algunas materias más difíciles en ciertos periodos, que muchas veces los padres no tenemos tiempo de llevar a cabo. Esta actividad extraescolar se desarrolla en grupos reducidos homogéneos en edad y curso.",
      "Recomendado para:",
      "- Educación Primaria"
    ]
  }
];
// Servicios de Conciliación
const act04 = [
  {
    nombre: "Los primeros del cole",
    texto: [
      "El aula de madrugadores es un servicio necesario para todas aquellas familias que necesitan dejar a sus hijos antes del horario escolar. Con este servicio fomentamos en los alumnos hábitos de alimentación, higiene, además de ser un espacio de juego y relax. El equipo de monitores acompaña a los alumnos en todo momento, ofreciéndoles espacio de juego, talleres, y actividades al ritmo de cada niño.",
      "Recomendado para:",
      "- Todos los niños del cole",
      "- De lunes a viernes (7:30 a 9:00 horas)"
    ]
  },
  {
    nombre: "Cole de junio o septiembre",
    texto: [
      "El inicio y fin del curso escolar entre los meses de septiembre y junio suele ser un problema para muchas familias con imposibilidad de ajustar sus horarios laborales a los de los pequeños. Esta actividad permite adaptar la jornada escolar al horario normal que tienen los niños durante el curso.",
      "Recomendado para:",
      "- Todos los niños del cole",
      "- De lunes a viernes (13:00 a 15:00 horas)"
    ]
  },
  {
    nombre: "Ludotecas a medida",
    texto: [
      "Reunión de padres, ¿con quién dejo a mis hijos? nosotros te ofrecemos un servicio de ludoteca donde los niños juegan, se divierten, y realizan talleres, mientras los adultos están reunidos. ¡Consulta sin compromiso, porque en Aristas queremos ayudarte!",
      "Recomendado para:",
      "- Todos los niños del cole",
      "- Horario personalizado"
    ]
  }
];
// Para padres
const act05 = [
  {
    nombre: "Escuela para padres + ludoteca infantil",
    texto: [
      "Nuestro departamento está compuesto por personas altamente cualificadas, contamos con logopedas, psicólogos y pedagogos. Nos preocupan los mismos temas que a ti y tenemos inquietudes sobre la evolución de los más pequeños.",
      "Durante años nos hemos formado para aplicar nuevas metodologias de aprendizaje, hemos construido un poliedro donde la educación de los más pequeños, y todos sus términos son nuestro mayor foco de atención.",
      "Talleres novedosos:",
      "- ¡Stop bulling!",
      "- Adictos a las redes sociales",
      "- Ana y Mía",
      "- Rabietas",
      "- Técnicas de estudio",
      "- Terapias familiares"
    ]
  },
  {
    nombre: "Extraescolares para adultos",
    texto: ["- Yoga", "- Pilates", "- Bodypump", "- Zumba"]
  }
];
// Consolidacion datos

const act00_mod = act00.map((item, index) => ({
  key: index,
  tipo: "act00",
  ...item
}));
const act01_mod = act01.map((item, index) => ({
  key: 100 + index,
  tipo: "act01",
  ...item
}));
const act02_mod = act02.map((item, index) => ({
  key: 200 + index,
  tipo: "act02",
  ...item
}));
const act03_mod = act03.map((item, index) => ({
  key: 300 + index,
  tipo: "act03",
  ...item
}));
const act04_mod = act04.map((item, index) => ({
  key: 400 + index,
  tipo: "act04",
  ...item
}));
const act05_mod = act05.map((item, index) => ({
  key: 500 + index,
  tipo: "act05",
  ...item
}));

const datos = act00_mod.concat(
  act01_mod.concat(
    act02_mod.concat(act03_mod.concat(act04_mod.concat(act05_mod)))
  )
);

export default datos;
